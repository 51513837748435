import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, Link, RouterProvider } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from './Navigation';
import { Col, Container, Row } from 'react-bootstrap';
import ndc from './ndc.json'
import Drug from './Drug';
import { hydrate, render } from "react-dom";

const routes = []
routes.push({
  path: '/',
  element: <App />
})

ndc.forEach((drug) => {
  routes.push({
    path: drug.ndc,
    element: <Drug ndc={drug.ndc} />,
  })
})

routes.push({
  path: '/*',
  element: <App notFound={true} />
})

const router = createBrowserRouter(routes);


 
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<React.StrictMode>
    <Navigation />
    <Container>
      <Row>
        <Col>
    </Col>
    </Row>
    <Row>
      <Col>
    <RouterProvider router={router}/>
    </Col>
    </Row>
    </Container>
    
  </React.StrictMode>, rootElement);
} else {
  render(<React.StrictMode>
    <Navigation />
    <Container>
      <Row>
        <Col>
    </Col>
    </Row>
    <Row>
      <Col>
    <RouterProvider router={router}/>
    </Col>
    </Row>
    </Container>
    
  </React.StrictMode>, rootElement);
}

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
  
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
