import React, { useEffect, useState } from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import { redirect } from "react-router-dom";
import DrugRow from "./DrugRow";

export default function Drug(props) {
  const [data, setData] = useState([]);
  const [nav, setNav] = useState([])
  const [rows, setRows] = useState([])

  

  useEffect(() => {
    fetch(
      `https://api.fda.gov/drug/label.json?search=openfda.package_ndc.exact:${props.ndc}`
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.error);
        }
      })
      .then((res) => {
        setData(res.results[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.ndc]);

  useEffect(() => {
    setNav([])
    const newArr = []
    for (var keys in data) {
        if (Array.isArray(data[keys])) {
            if (keys !== 'spl_product_data_elements' && keys !== 'spl_unclassified_section' && keys !== 'spl_product_data_elements') {
                newArr.push(<ListGroup.Item action  key={keys} href={`#${keys}`}>{keys[0].toUpperCase() + keys.substring(1).replace(/_/g, " ")}</ListGroup.Item>)
            }
            
        }
    }
    setNav(newArr)
  }, [data])

  useEffect(() => {
    setRows([])
    const newArr = []
    for (var keys in data) {
        if (Array.isArray(data[keys])) {
            if (keys !== 'spl_product_data_elements' && keys !== 'spl_unclassified_section' && keys !== 'spl_product_data_elements') {
            newArr.push(<DrugRow key={'row' + keys} title={keys} text={data[keys]} />)
            }
        }
    }
    setRows(newArr)
  }, [data])

  return (
    <div>
      <Row>
        <Col>
        <h1>{(data.openfda && data.openfda.brand_name[0]) ?? 'Drug Information'}</h1>
        </Col>
      </Row>
      <Row>
        <Col xl='6'>
      
      {data.openfda && data.openfda.manufacturer_name[0] && <p className='text-muted'>{data.openfda.manufacturer_name[0]}</p>}
      <div>Selected NDC: {props.ndc}</div>
      <p>Alternate NDC: {data.openfda && data.openfda.package_ndc.length > 0 && data.openfda.package_ndc.map((ndc, i) => {
        return i < data.openfda.package_ndc.length -1  ? ndc + ', ' : ndc
      })}</p>
      </Col>
      <Col xl='6'>
        <p>Mechanism of Action (MoA): <small>{data.openfda && data.openfda.pharm_class_moa && data.openfda.pharm_class_moa.map(phmclass => <li>{phmclass}</li>)}</small></p>
        <p>Established Pharmacologic Class (EPC): <small>{data.openfda && data.openfda.pharm_class_epc && data.openfda.pharm_class_epc.map(phmclass => <li>{phmclass}</li>)}</small></p>
      </Col>
      </Row>
      <Row>
      <Col lg='2'>
        <ListGroup className='mb-3'>
            {nav && nav.length > 0 && nav.map((item, i) => {
                return item
            })}
        </ListGroup>
      </Col>
      <Col lg='10'>
            {rows.map((row) => {
                return row
            })}
      {/* {data.indications_and_usage && <Card className='m-3 shadow'>
        <Card.Body>
            <Card.Title>Indications and Usage</Card.Title>
            <div>{data.indications_and_usage.map(dat => {
                return <p>{dat}</p>
            })}</div>
        </Card.Body>
      </Card>}
      <Card className='m-3 shadow'>
        <Card.Body>
            <Card.Title>Dosage and Administration</Card.Title>
            <div>{data.dosage_and_administration.map(dat => {
                return <p>{dat}</p>
            })}</div>
        </Card.Body>
      </Card>
      <Card className='m-3 shadow'>
        <Card.Body>
            <Card.Title>Contraindications</Card.Title>
            <div>{data.contraindications.map(dat => {
                return <p>{dat}</p>
            })}</div>
        </Card.Body>
      </Card>
      <Card className='m-3 shadow'>
        <Card.Body>
            <Card.Title>Warnings and Precautions</Card.Title>
            <div>{data.warnings.map(dat => {
                return <p>{dat}</p>
            })}</div>
            <div>{data.precautions.map(dat => {
                return <p>{dat}</p>
            })}</div>
        </Card.Body>
      </Card>
      <Card className='m-3 shadow'>
        <Card.Body>
            <Card.Title>Adverse Reactions</Card.Title>
            <div>{data.adverse_reactions.map(dat => {
                return <p>{dat}</p>
            })}</div>
        </Card.Body>
      </Card>
      <Card className='m-3 shadow'>
        <Card.Body>
            <Card.Title>Drug Interactions</Card.Title>
            <div>{data.drug_interactions.map(dat => {
                return <p>{dat}</p>
            })}</div>
        </Card.Body>
      </Card>
      <Card className='m-3 shadow'>
        <Card.Body>
            <Card.Title>Use In Specific Populations</Card.Title>
            <div>{data.pregnancy.map(dat => {
                return <p>{dat}</p>
            })}</div>
            <div>{data.nursing_mothers.map(dat => {
                return <p>{dat}</p>
            })}</div>
            <div>{data.pediatric_use.map(dat => {
                return <p>{dat}</p>
            })}</div>
            <div>{data.geriatric_use.map(dat => {
                return <p>{dat}</p>
            })}</div>
        </Card.Body>
      </Card>
      <Card className='m-3 shadow'>
        <Card.Body>
            <Card.Title>Overdosage</Card.Title>
            <div>{data.overdosage.map(dat => {
                return <p>{dat}</p>
            })}</div>
        </Card.Body>
      </Card>
      <Card className='m-3 shadow'>
        <Card.Body>
            <Card.Title>Description</Card.Title>
            <div>{data.description.map(dat => {
                return <p>{dat}</p>
            })}</div>
        </Card.Body>
      </Card>
      <Card className='m-3 shadow'>
        <Card.Body>
            <Card.Title>Clinical Pharmacology</Card.Title>
            <div>{data.clinical_pharmacology.map(dat => {
                return <p>{dat}</p>
            })}</div>
        </Card.Body>
      </Card>
      <Card className='m-3 shadow'>
        <Card.Body>
            <Card.Title>How Supplied</Card.Title>
            <div>{data.how_supplied.map(dat => {
                return <p>{dat}</p>
            })}</div>
        </Card.Body>
      </Card> */}
      </Col>
      </Row>
    </div>
  );
}
