import React from 'react'
import { Container, Nav, Navbar, Form } from 'react-bootstrap'

export default function Navigation(props) {
    return <Navbar bg="dark" variant="dark">
    <Container fluid>
      <Navbar.Brand href="/" className='text-capitalize'>Werner Drugs</Navbar.Brand>
      <Nav className="me-auto">
        <Nav.Link href="/">Home</Nav.Link>
        <Form></Form>
      </Nav>
    </Container>
  </Navbar>
}