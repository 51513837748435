import logo from "./logo.svg";
import "./App.css";
import { Alert, Col, Form, ListGroup, Row, Spinner } from "react-bootstrap";
import Fuse from "fuse.js";
import { useEffect, useState } from "react";
import ndc from "./ndc.json"
import { Link } from "react-router-dom";

function App(props) {

  const options = {
    includeScore: true,
    threshold: 0.3,
    keys: ['name', 'ndc']
  }
  

  const [searchResults, setSearchResults] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [searching, setSearching] = useState(false)
  const [routes, setRoutes] = useState([])

  useEffect(() => {
    setSearchResults([])
    setSearching(true)
    const delaySearch = setTimeout(() => {
      if (searchTerm) {
        const fuse = new Fuse(ndc, options)
        setSearchResults([])
        const result = fuse.search(searchTerm)
        //console.log(result)
        setSearchResults(result.slice(0,20))
        setSearching(false)
      } else {
        setSearching(false)
      }
    }, 1000);
    return () => clearTimeout(delaySearch);
  }, [searchTerm])

  useEffect(() => {
    const routes = []
routes.push({
  path: '/'
})

ndc.forEach((drug) => {
  routes.push({
    path: drug.ndc
  })
})
setRoutes(routes)
  }, [])

  return (
    <div className="App">
      <h1 className="text-uppercase m-5">Werner Drugs</h1>
      <p className="text-muted">
        Designed by <a href="https://rswerner.com">Ryan S Werner</a>; data
        provided by <a href="https://open.fda.gov/">OpenFDA</a>
      </p>
      {props.notFound && (
        <Alert variant="danger">
          <Alert.Heading>Drug Not Found</Alert.Heading>
          <p>
            The drug that you entered was not found in our database. Please try
            again.
          </p>
        </Alert>
      )}
      <Row>
        <Col>
          <Form
            style={{ textAlign: "left" }}
            className="d-flex flex-row justify-content-center m-5"
          >
            <Form.Group controlId="search">
              <Form.Label></Form.Label>
              <Form.Control
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
                style={{ minWidth: "30rem" }}
                placeholder="Search"
              ></Form.Control>
              <Form.Text>
                Try searching for things like "Clonazepam", "72888-152-01", or
                "Klonopin"
              </Form.Text>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
        <div className='d-flex flex-row justify-content-center' style={{textAlign: 'left'}}>
        <ListGroup style={{minWidth: '30rem'}}>
          {searchResults && searchResults.length > 0 && searchResults.map((result, i) => {
            return <ListGroup.Item action href={`/${result.item.ndc}`} key={i}><div className='d-flex flex-column'><div>{result.item.name[0]}</div><div className='text-muted'>{result.item.ndc}</div></div></ListGroup.Item>
          })}
        </ListGroup>
        {searching && <Spinner animation='border' />}
        </div>
        </Col>
      </Row>
      {/* {routes.length > 0 && routes.map(route => <Link key={route.path} to={route.path}></Link>)} */}
    </div>
  );
}

export default App;
