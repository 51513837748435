import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'

export default function DrugRow(props) {

    const [parsedText, setParsedText] = useState('')

    
        
    useEffect(() => {
        
        let textArr = []
        let statements = JSON.parse(JSON.stringify(props.text))
        if (statements[0][0] !== '<') {
            statements.forEach((statement) => {
                const sentences = statement.split('. ')
                const sentencesWithPers = sentences.map((sen) => {
                    return sen + '. '
                } )
                // sentencesWithPers.forEach(sen => {
                //     textArr.push(sen)
                // })
                let arrayPos = 0
                for (var i = 0; i < sentencesWithPers.length; i++) {
                    //console.log(sentencesWithPers)
                    if (sentencesWithPers[i].includes(':')) {
                        arrayPos++
                        textArr[arrayPos] = textArr[arrayPos] ? textArr[arrayPos] + sentencesWithPers[i] : sentencesWithPers[i]
                    } else {
                        textArr[arrayPos] = textArr[arrayPos] ? textArr[arrayPos] + sentencesWithPers[i] : sentencesWithPers[i]
                    }
                }
            })
            setParsedText(textArr)
        } else {
            setParsedText(statements)
        }
        
        
    }, [props.text])

    return <Card className='mb-5 shadow' bg={props.title === "boxed_warning" ? 'dark' : null} text={props.title === "boxed_warning" ? 'light' : null} id={props.title}>
        <Card.Body>
        <Card.Title>{props.title[0].toUpperCase() + props.title.substring(1).replace(/_/g, " ")}</Card.Title>
        {parsedText && parsedText.map((text, i) => {
            if (text[0] === '<') {
                let newT = text.replace('t', 'T')
                return <div dangerouslySetInnerHTML={{__html: newT}} />
            } else {
                return <p key={`${props.title}${i}`}>{text}</p>
            }
            
        })}
        </Card.Body>
    </Card>
}